<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <div>Filters</div>

        <v-spacer />

        <v-btn icon small @click="hideFilter = !hideFilter">
          <v-icon small color="primary">mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn icon small>
          <v-icon small color="primary" @click="resetFilter()">mdi-reload</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-show="!hideFilter" class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Name"
              v-model="query.name"
              placeholder="e.g. 2 Northdown"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="query.type"
              :items="supplierTypes"
              :item-text="'name'"
              :item-value="'_id'"
              label="Type"
              dense
              clearable
              @click:clear="clear('type')"
              outlined
              :class="{ 'v-input-has-value ': query.type }"
            ></v-autocomplete>
            <v-col cols="12" sm="4">
          </v-col>
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
              v-model="query.status"
              :items="statuses"
              :item-text="'text'"
              :item-value="'value'"
              label="Status"
              dense
              clearable
              @change="statusChange()"
              @click:clear="clear('status')"
              outlined
            ></v-select>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-spacer />
          <v-col cols="12" sm="4" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="6">
                <v-btn :loading="loading.export" block outlined color="primary" @click="exportExcel()">Export</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" @click="changeRoute()">Add new</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="suppliers" hide-default-footer>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | capitalize }}</span>
        </template>
        <template v-slot:[`item.organisers`]="{ item }">
          <router-link v-if="item.assignToAllOrganiser" :to="{ name: 'admin.promoters', params: { } }">{{ organiserCount }} promoters</router-link>
          <a v-if="!item.assignToAllOrganiser" href="javascript:void(0)" @click="viewPromoters(item.organisers)">{{ item.organisers.length }} promoters</a>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="editItem(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Delete" class="mr-1" @click="deleteItem(item)">
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-if="dialog" v-model="dialog" width="500" height="30vh">
    <v-card min-height="30vh">
      <v-card-text>
        <v-card-title>Promoters</v-card-title>
        <v-data-table
          :headers="promoterHeaders"
          item-key="_id"
          :items="promoters"
        >
        </v-data-table>
        <v-spacer />
        <v-card-actions>
          <div class="mx-auto">
            <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>
          </div>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { SupplierService, SupplierTypeService } from '@services';
export default {
  data() {
    return {
      query: {
        search: '',
        page: 1,
        name: '',
        type: '',
        status: '',
        quantity: '',
      },
      totalPage: '',
      headers: [
        { text: 'Actions', value: 'actions', align: 'start', sortable: false, width: '100px' },
        {
          text: 'Supplier Id',
          sortable: false,
          value: 'supplierID',
          width: '80px',
        },
        {
          text: 'Suppliers',
          sortable: false,
          value: 'name',
          width: '150px',
        },
        {
          text: 'Contact',
          sortable: false,
          value: 'contactNumber',
          width: '100px',
        },
        {
          text: 'Type',
          sortable: false,
          value: 'supplierType.name',
          width: '100px',
        },
        {
          text: 'Assign To',
          sortable: false,
          value: 'organisers',
          width: '100px',
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
          width: '80px',
        },
      ],
      desserts: [],
      suppliers: [],
      loading: {
        export: false,
      },
      supplierTypes: [],
      statuses: [
        { text: 'All', value: 'all' },
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      hideFilter: false,
      dialog: false,
      promoters: [],
      promoterHeaders: [
        { text: 'Name', value: 'name', sortable: false, width: '150px' },
      ],
      organiserCount: 0,
    };
  },
  async created() {
    Object.assign(this.query, this.$getState());
    this.getSuppliers();
    this.getSupplierTypes();
  },
  methods: {
    async getSuppliers() {
      const supplierData = await SupplierService.getSuppliers(this.query);
      this.suppliers = supplierData.supplier;
      this.totalPage = Number(parseInt((supplierData.totalCount + 10 - 1) / 10));
      this.organiserCount = supplierData.organiserCount;
    },
    async getSupplierTypes() {
      const supplierTypeData = await SupplierTypeService.getSupplierTypes({ limit: 'all' });
      this.supplierTypes = supplierTypeData.supplierTypes;
    },
    resetFilter() {
      this.query.name = '';
      this.query.name = '';
      this.query.status = '';
      this.query.quantity = '';
      this.query.page = 1;
      this.getSuppliers();
    },
    changeRoute() {
      this.$next({ name: 'admin.suppliers.add' });
    },
    editItem(item) {
      this.$next({ name: 'admin.suppliers.edit', params: { id: item._id } });
    },
    async deleteItem(item) {
      this.$confirm({
        title: `Are you sure you want to delete this Supplier?`,
        resolve: async () => {
          await SupplierService.delete(item._id);
          this.getSuppliers();
        },
      });
    },
    statusChange() {
      this.getSuppliers();
    },
    async exportExcel() {
      this.loading.export = true;
      const exportdata = await SupplierService.exportExcel(this.query);
      const url = URL.createObjectURL(new Blob([exportdata]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Suppliers-${moment().format('DD/MM/YYYY')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.loading.export = false;
    },
    viewPromoters(data) {
      this.dialog = true;
      this.promoters = data;
    },
    onCancel() {
      this.dialog = false;
    },
  },
  watch: {
    'query.name': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.name && this.query.name.length > 2) {
          this.query.page = 1;
          this.getSuppliers();
        }
        if (!this.query.name) {
          this.query.page = 1;
          this.getSuppliers();
        }
      }, 600);
    },
    'query.quantity': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.quantity && this.query.quantity.length > 0) {
          this.query.page = 1;
          this.getSuppliers();
        }
        if (!this.query.quantity) {
          this.query.page = 1;
          this.getSuppliers();
        }
      }, 600);
    },
    'query.page': function() {
      this.getSuppliers();
    },
    'query.type': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.type && this.query.type.length > 2) {
          this.query.page = 1;
          this.getSuppliers();
        }
        if (!this.query.type) {
          this.query.page = 1;
          this.getSuppliers();
        }
      }, 600);
    }
  }
};
</script>