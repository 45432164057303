<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Supplier</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
                outlined
                dense
                name="name"
                clearable
                label="Name"
                @change="onChangeName(), generateSupplierId()"
                v-model="data.name"
                @input="nameError = []"
                :error-messages="nameError"
                :rules="[
                  v => !!v && v && !!v.trim() || 'Name is required', 
                  v => (v && v.length < 50) || 'Name must less than 50 character'
                ]"
                id="name"
                placeholder="e.g. Name of supplier"
              />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              name="contactNumber"
              clearable
              label="Contact"
              v-model="data.contactNumber"
              :rules="[
                v => !!v && v && !!v.trim() || 'Contact number is required', 
                v => !v || /^\d{11}$/.test(v) || 'Contact number must be valid',
              ]"
              id="contactNumber"
              placeholder="e.g. Supplier Contact"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              name="email"
              clearable
              label="Email"
              v-model="data.email"
              :rules="[v => !!v && v && !!v.trim() || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
              id="email"
              placeholder="e.g. Supplier Email"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="data.supplierType"
              :items="supplierTypes"
              item-text="name"
              clearable
              item-value="_id"
              label="Supplier Type"
              :rules="[v => !!v || 'Supplier type is required']"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn outlined color="primary" @click="addSupplierType()">Add new type</v-btn>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              name="supplierID"
              clearable
              label="Supplier ID"
              v-model="data.supplierID"
              id="supplierID"
              :rules="[
                v => (v && v.length < 11) || 'Supplier ID must less than 10 character'
              ]"
              placeholder="Supplier ID"
            />
          </v-col>
          <v-col cols="12" sm="1" class="mt-2">
            Active
          </v-col>
          <v-col class="mt-2">
            <v-switch class="pa-0 ma-0" v-model="data.status1" >
              <template v-slot:label>
                {{data.status1 ? 'On' : 'Off'}}
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="3" class="mt-2">
              Assign Products to all promoters?
            </v-col>
            <v-col cols="12" sm="1" class="mt-2">
              <v-switch class="pa-0 ma-0" v-model="data.assignToAllOrganiser" ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" v-show="!data.assignToAllOrganiser">
              <v-autocomplete
                v-model="data.organisers"
                multiple
                :items="organisers"
                clearable
                :filter="customFilter"
                item-text="name"
                item-value="_id"
                label="Promoter"
                :rules="[v => !!v || 'Promoter is required']"
                dense
                outlined
              >
                <!--template v-slot:selection="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
                </template>
                <template v-slot:item="data">
                  <v-checkbox
                  v-model="data.selectedOrganisers"
                  :label="`${data.item.name } ( ${ data.item.type } )`"
                  ></v-checkbox>
                </template-->
              </v-autocomplete>
            </v-col>
            
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading.save">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" width="400px">
      <v-overlay :value="loadingSupplierType">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card min-height="30vh">
        <v-form ref="supplierAddForm" v-model="supplierAddForm" lazy-validation>
          <v-card-title class="text-bold text-h6">Supplier Type </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  name="typeName"
                  clearable
                  label="Add Type Name"
                  v-model="supplierTypeData.name"
                  :rules="[
                    v => !!v && v && !!v.trim() || 'Type Name is required', 
                    v => (v && v.length < 20) || 'Type Name must less than 20 character'
                  ]"
                  id="supplierType"
                  placeholder="Add new type"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <div class="mx-auto">
              <v-spacer />
              <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>
              <v-btn
                class="mx-1"
                width="100"
                :loading="loading.saveSupplier"
                color="primary"
                @click="saveSupplier()"
                >Save</v-btn
              >
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { SubCategoryService, PromoterService, SupplierTypeService, SupplierService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: {
        supplierTypes: false,
        save: false,
        saveSupplier: false,
        delete: false,
      },
      text: 'Add',
      data: {
        assignToAllOrganiser: false,
        supplierID: '',
        status: 'active',
        status1: true,
      },
      supplierTypeData: {

      },
      nameError: [],
      organisers: [],
      dialog: false,
      supplierAddForm: false,
      loadingSupplierType: false,
      supplierTypes: [],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getSupplierById(this.$route.params.id);
    }
    this.getSupplierTypes();
    this.getPromoters();
  },
  methods: {
    async getSupplierById(id) {
      this.data = await SupplierService.getById(id);
      if(this.data.status === 'inactive') {
        this.data.status1 = false;
      }
      if(this.data.status === 'active') {
        this.data.status1 = true;
      }
    },
    async getPromoters() {
      const promoterdata = await PromoterService.get({ limit: 'all', type: 'promoter' });
      this.organisers = promoterdata.organisers;
    },
    async getSupplierTypes() {
      const supplierTypeData = await SupplierTypeService.getSupplierTypes({ limit: 'all' });
      this.supplierTypes = supplierTypeData.supplierTypes;
    },
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    async save() {
      if (this.$refs.form.validate() && !this.nameError.length) {
        this.loading.save = true;
        if(!this.data.status1) {
          this.data.status = 'inactive';
        } else if(this.data.status1) {
          this.data.status = 'active';
        }
        if (this.data._id) {
          const response = await SupplierService.update(this.data, this.data._id);
          if (response) {
            this.$next({ name: 'admin.suppliers' });
          }
        } else {
          const response = await SupplierService.save(this.data);
          if (response) {
            this.$next({ name: 'admin.suppliers' });
          }
        }
        this.loading.save = false;
      }
    },
    discard() {
      this.$next({ name: 'admin.suppliers' });
    },
    addSupplierType() {
      this.dialog = true;
    },
    async saveSupplier() {
      if (this.$refs.supplierAddForm.validate() && !this.nameError.length) {
        this.loading.saveSupplier = true;
        const data = await SupplierTypeService.saveSupplierType(this.supplierTypeData);
        if(data) {
          this.supplierTypeData = {};
          this.dialog = false;
          await this.getSupplierTypes();
          this.data.supplierType = data._id || '';
        }
        this.loading.saveSupplier = false;
      }
    },
    onCancel() {
      this.supplierTypeData = {};
      this.dialog = false;
    },
    generateSupplierId(data) {
      if (!this.data.supplierID) {
        this.data.supplierID = this.randomString(10, '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
      }
    },
    async onChangeName() {
      const response = await SupplierService.checkDuplicate(this.data.name);
      if (response && response._id != this.data._id) {
        this.nameError = ['Name already exists'];
      }
    }
  },
};
</script>